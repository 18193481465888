import { takeLatest, put, all, call } from "typed-redux-saga/macro";
import { CreateLocationStart, DeleteLocationStart, FetchLocationStart, UpdateLocationCoordsStart, UpdateLocationNameStart, createLocationFailed, createLocationSuccess, deleteLocationFailed, deleteLocationSuccess, fetchLocationFailed, fetchLocationSuccess, updateLocationCoordsFailed, updateLocationCoordsSuccess, updateLocationNameFailed, updateLocationNameSuccess } from "./location.action";
import {  getCustomerLocations,addCustomerLocation, updateCustomerLocationCoordinates, updateCustomerLocationName, deleteCustomerLocation } from "../../utils/eqonapi/eqonapi.utils";
import { LOCATION_ACTION_TYPES } from "./location.types";

const maxTries = 3;   //number of tries for a failed api call. 1 means no retries 

export function* fetchLocation({payload}:FetchLocationStart){
    let retries = 0;
    while(retries<maxTries){
        try{        
            const response = yield* call(getCustomerLocations, payload);   //response is the json response from fetch
            if(response){
                yield* put(fetchLocationSuccess(response));
                break;           
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(fetchLocationFailed(error as Error));
            }
        }
    }
}

export function* createLocation({payload:{user,location}}:CreateLocationStart){
    let retries = 0;
    while(retries<maxTries){
        try{        
            const response = yield* call(addCustomerLocation, user, location.location_name, location.level as number);   //response is the json response from fetch
            if(response){
                yield* put(createLocationSuccess(response));        
                break;   
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(createLocationFailed(error as Error));
            }
        }
    }
}

export function* updateLocationName({payload:{user,location}}:UpdateLocationNameStart){
    let retries = 0;
    while(retries<maxTries){
        try{        
            const response = yield* call(updateCustomerLocationName, user, location);   //response is the json response from fetch
            if(response){
                yield* put(updateLocationNameSuccess(response));       
                break;    
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(updateLocationNameFailed(error as Error));
            }
        }
    }
}

export function* updateLocationCoords({payload:{user,location}}:UpdateLocationCoordsStart){
    let retries = 0;
    while(retries<maxTries){
        try{        
            const response = yield* call(updateCustomerLocationCoordinates, user, location);   //response is the json response from fetch
            if(response){
                yield* put(updateLocationCoordsSuccess(response)); 
                break;          
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(updateLocationCoordsFailed(error as Error));
            }
        }
    }
}

export function* deleteLocation({payload:{user,location_id}}:DeleteLocationStart){
    let retries = 0;
    while(retries<maxTries){
        try{        
            const response = yield* call(deleteCustomerLocation, user, location_id);   //response is the json response from fetch
            if(response){
                yield* put(deleteLocationSuccess(response, location_id));   
                break;        
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(deleteLocationFailed(error as Error));
            }
        }
    }
}


export function* onFetchLocationStart() {
    yield* takeLatest(LOCATION_ACTION_TYPES.FETCH_LOCATION_START, fetchLocation)
}

export function* onCreateLocationStart() {
    yield* takeLatest(LOCATION_ACTION_TYPES.CREATE_LOCATION_START, createLocation)
}

export function* onUpdateLocationNameStart() {
    yield* takeLatest(LOCATION_ACTION_TYPES.UPDATE_LOCATION_NAME_START, updateLocationName)
}

export function* onUpdateLocationCoordsStart() {
    yield* takeLatest(LOCATION_ACTION_TYPES.UPDATE_LOCATION_COORDS_START, updateLocationCoords)
}

export function* onDeleteLocationStart() {
    yield* takeLatest(LOCATION_ACTION_TYPES.DELETE_LOCATION_START, deleteLocation)
}



/*"Listen" to the sagas*/ 
export function* locationSagas() {
    yield* all([
        call(onFetchLocationStart),
        call(onCreateLocationStart),
        call(onUpdateLocationNameStart),
        call(onUpdateLocationCoordsStart),
        call(onDeleteLocationStart),
    ]);
}