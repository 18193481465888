export enum CUSTOMER_ACTION_TYPES  {
    DELETE_CUSTOMER_START = "customer/DELETE_CUSTOMER_START",
    DELETE_CUSTOMER_SUCCESS = "customer/DELETE_CUSTOMER_SUCCESS",
    DELETE_CUSTOMER_FAILED = "customer/DELETE_CUSTOMER_FAILED",
    ADD_CUSTOMER_START = "customer/ADD_CUSTOMER_START",
    ADD_CUSTOMER_SUCCESS = "customer/ADD_CUSTOMER_SUCCESS",
    ADD_CUSTOMER_FAILED = "customer/ADD_CUSTOMER_FAILED",
    UPDATE_CUSTOMER_START = "customer/UPDATE_CUSTOMER_START",
    UPDATE_CUSTOMER_SUCCESS = "customer/UPDATE_CUSTOMER_SUCCESS",
    UPDATE_CUSTOMER_FAILED = "customer/UPDATE_CUSTOMER_FAILED",
    FETCH_CUSTOMER_START = "customer/FETCH_CUSTOMER_START",
    FETCH_CUSTOMER_SUCCESS = "customer/FETCH_CUSTOMER_SUCCESS",
    FETCH_CUSTOMER_FAILED = "customer/FETCH_CUSTOMER_FAILED",

}