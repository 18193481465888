import { combineReducers } from "redux";
import { userReducer } from "./user/user.reducer";
import { deviceReducer } from "./device/device.reducer";
import { deviceIdReducer } from "./idsetting/idsetting.reducer";
import { deviceSettingReducer } from "./setting/setting.reducer";
import { deviceHighFreqDataReducer } from "./highfreqdata/highfreqdata.reducer";
import { deviceLowFreqDataReducer } from "./lowfreqdata/lowfreqdata.reducer";
import { customerReducer } from "./customer/customer.reducer";
import { alertsReducer } from "./alert/alert.reducer";
import { reportsReducer } from "./reports/reports.reducer";
import { locationsReducer } from "./location/location.reducer";

export const rootReducer = combineReducers({
    user: userReducer,
    device: deviceReducer,
    idSettings: deviceIdReducer,
    settings: deviceSettingReducer,
    highFreqData: deviceHighFreqDataReducer,
    lowFreqData: deviceLowFreqDataReducer,
    customers: customerReducer,
    alerts: alertsReducer,
    reports: reportsReducer,
    locations: locationsReducer,
});