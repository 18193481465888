import { AnyAction } from "redux";
import { addCustomerSuccess, addCustomerFailed, fetchCustomerSuccess, fetchCustomerFailed, updateCustomerSuccess, updateCustomerFailed, deleteCustomerSuccess, deleteCustomerFailed } from "./customer.action";
import { toast } from "react-toastify";
import { Customer } from "../../models/customer.model";

export type CustomerState = {
    readonly customers : Customer[] | null;
    readonly lastUpdate : Date;
    readonly error: Error | null;
}

const INITIAL_STATE : CustomerState = {
    customers: null,
    lastUpdate : new Date("2020-01-01T12:00:00.000000Z"),
    error: null,
}


export const customerReducer = (state = INITIAL_STATE, action: AnyAction) => {

    if(addCustomerSuccess.match(action)){
        toast.success("Added customer successfully")
        return {                         
            ...state,
            error: null
        }
    }
    
    if(addCustomerFailed.match(action)){
        toast.error("Failed to add customer")
        return { 
            ...state,
            error: action.payload             
        } 
    }

    if(fetchCustomerSuccess.match(action)){
        return {                         
            ...state,
            lastUpdate : new Date(),
            customers: action.payload.customers,
            error: null
        }
    }
    
    if(fetchCustomerFailed.match(action)){
        toast.error("Failed to retrieve customers")
        return { 
            ...state,
            error: action.payload             
        } 
    }

    if(updateCustomerSuccess.match(action)){
        toast.success("Updated customer successfully")
        return {                         
            ...state,
            error: null
        }
    }
    
    if(updateCustomerFailed.match(action)){
        toast.error("Failed to update customer")
        return { 
            ...state,
            error: action.payload             
        } 
    }

    if(deleteCustomerSuccess.match(action)){
        toast.success("Deleted customer successfully")
        return {                         
            ...state,
            error: null
        }
    }
    
    if(deleteCustomerFailed.match(action)){
        toast.error("Failed to delete customer")
        return { 
            ...state,
            error: action.payload             
        } 
    }
    
    return state;
};