import { AnyAction } from "redux";
import { fetchDailyReportsFailed, fetchDailyReportsSuccess, fetchReportsFailed, fetchReportsSuccess, fetchWeeklyReportsFailed, fetchWeeklyReportsSuccess } from "./reports.action";
import { toast } from "react-toastify";
import { DailyReport, Report, WeeklyReport } from "../../models/report.model";

export type ReportsState = {
    readonly reports : Report[] | null;
    readonly error: Error | null;
    readonly dailyreport : DailyReport | null;
    readonly weeklyreport : WeeklyReport | null;
    readonly lastUpdate : Date;
}

const INITIAL_STATE : ReportsState = {
    reports: null,
    error: null,
    dailyreport: null,
    weeklyreport: null,
    lastUpdate: new Date("2020-01-01T12:00:00.000000Z"),
}


export const reportsReducer = (state = INITIAL_STATE, action: AnyAction) => {

    if(fetchReportsSuccess.match(action)){
        return {             
            ...state,
            reports: action.payload,
            error: null
        }
    }
    
    if(fetchReportsFailed.match(action)){
        toast.error("failed to get reports")
        return { 
            ...state,
            error: action.payload             
        } 
    }

    if(fetchDailyReportsSuccess.match(action)){
        return {             
            ...state,
            dailyreport: action.payload,
            lastUpdate: new Date(),
            error: null
        }
    }
    
    if(fetchDailyReportsFailed.match(action)){
        toast.error("failed to get daily report")
        return { 
            ...state,
            error: action.payload             
        } 
    }

    if(fetchWeeklyReportsSuccess.match(action)){
        return {             
            ...state,
            weeklyreport: action.payload,
            error: null
        }
    }
    
    if(fetchWeeklyReportsFailed.match(action)){
        toast.error("failed to get weekly report")
        return { 
            ...state,
            error: action.payload             
        } 
    }
    
    return state;
};