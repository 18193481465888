import { takeLatest, put, all, call } from "typed-redux-saga/macro";

import { USER_ACTION_TYPES } from "./user.types";
import { signInSuccess, signInFailed,  EmailSignInStart, SignOutStart, signOutSuccess, AddUserStart, addUserSuccess, addUserFailed, FetchUserStart, fetchUserSuccess, fetchUserFailed, DeleteUserStart, deleteUserSuccess, deleteUserFailed, UpdateUserStart, updateUserSuccess, updateUserFailed, RefreshTokenStart, refreshTokenSuccess, refreshTokenFailed, MicrosoftSignInStart } from "./user.action";
import { createUser, deleteUserAPI, getUserList, patchUser, renewAccessToken, signInUser,signInUserMicrosoft } from "../../utils/eqonapi/eqonapi.utils";
import { HttpStatusCode } from "axios";
import { UserData } from "../../models/user.model";


const maxTries = 3;   //number of tries for a failed api call. 1 means no retries 


//We get an action as a parameter. The action has a payload of username and password
export function* signInNormal({payload: {username, password}}:EmailSignInStart){
      
    try{
        const response = yield* call(signInUser, username, password);   //response is the json response from fetch
        if(response){
            const {user} = response;    //Check if we have a "user" member in the response.
                if(!user){  //Sign in was not successful, we have an error
                throw new Error(response.error);
            }  

            if(user){   //Sign in was successful, we have a user object
                yield* put(signInSuccess(response));
            }               
        }
    }catch(error){
        yield* put(signInFailed(error as Error));
    }
}
//Todo: signOutNormal could call an API in the backend to disable the current session.
//signOutNormal will directly put sugnOutSuccess. The reducer will then set the user to null.
export function* signOutNormal({}:SignOutStart){        
    const nullUser : UserData| null = null     
    yield* put(signOutSuccess(nullUser)); 
}

//We get an action as a parameter. The action has a payload of username and password
export function* addUser({payload: {user, userToAdd}}:AddUserStart){
    let retries = 0;
    while(retries<maxTries){      
        try{
            const response = yield* call(createUser, user, userToAdd);   //response is the json response from fetch
            if(response){ 
                yield* put(addUserSuccess());
                break;
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(addUserFailed(error as Error));
            }
        }
    }
}


export function* fetchUser({payload}:FetchUserStart){
    let retries = 0;
    while(retries<maxTries){ 
        try{
            const response = yield* call(getUserList, payload);   //response is the json response from fetch
            if(response){
                yield* put(fetchUserSuccess(response));
                break;
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(fetchUserFailed(error as Error));
            }
        }
    }
}

export function* deleteUser({payload:{user,username}}:DeleteUserStart){
    let retries = 0;
    while(retries<maxTries){ 
        try{
            const response = yield* call(deleteUserAPI, user, username);   //response is the json response from fetch
            if (response===HttpStatusCode.Ok){
                yield* put(deleteUserSuccess());       
                break;
            }
        }catch(error){
            yield* put(deleteUserFailed(error as Error));
        }
    }
}

export function* updateUser({payload:{user,username, full_name, privileges, email}}:UpdateUserStart){
    let retries = 0;
    while(retries<maxTries){
        try{
            const response = yield* call(patchUser, user,username, full_name, privileges, email );   //response is the json response from fetch
            if (response.username){
                yield* put(updateUserSuccess(response));  
                break;     
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(updateUserFailed(error as Error));
            }
        }
    }
}

export function* refreshToken({payload:{user}}:RefreshTokenStart){    

    let retries = 0;
    while(retries<maxTries){
        try{
            const response = yield* call(renewAccessToken, user);   //response is the json response from fetch
            if (response.access_token){
                yield* put(refreshTokenSuccess(response.access_token, response.access_token_expires_at));  
                break;
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(refreshTokenFailed(error as Error));
            }
        }
    }
}

export function* signInMicrosoft({payload: {idtoken}}:MicrosoftSignInStart){
      
    try{
        const response = yield* call(signInUserMicrosoft, idtoken);   //response is the json response from fetch
        if(response){
            const {user} = response;    //Check if we have a "user" member in the response.
                if(!user){  //Sign in was not successful, we have an error
                throw new Error(response.error);
            }  

            if(user){   //Sign in was successful, we have a user object
                yield* put(signInSuccess(response));
            }               
        }
    }catch(error){
        yield* put(signInFailed(error as Error));
    }
}

// export function* isUserAuthenticated() {
//     try{
//         const userAuth = yield* call(getCurrentUser);
//         if(!userAuth) return;
//         yield* call(getSnapshotFromUserAuth,userAuth);
//     }catch(error){
//         yield* put(signInFailed(error as Error));
//     }
// }




export function* onEmailSignInStart() {
    yield* takeLatest(USER_ACTION_TYPES.EMAIL_SIGN_IN_START, signInNormal)
}

export function* onSignOutStart() {
    yield* takeLatest(USER_ACTION_TYPES.SIGN_OUT_START, signOutNormal)
}

export function* onAddUserStart() {
    yield* takeLatest(USER_ACTION_TYPES.ADD_USER_START, addUser)
}

export function* onFetchUsersStart() {
    yield* takeLatest(USER_ACTION_TYPES.FETCH_USERS_START, fetchUser)
}
export function* onDeleteUserStart() {
    yield* takeLatest(USER_ACTION_TYPES.DELETE_USER_START, deleteUser)
}

export function* onUpdateUserStart() {
    yield* takeLatest(USER_ACTION_TYPES.UPDATE_USER_START, updateUser)
}

export function* onRefreshTokenStart() {
    yield* takeLatest(USER_ACTION_TYPES.REFRESH_TOKEN_START, refreshToken)
}

export function* onMicrosoftSignInStart() {
    yield* takeLatest(USER_ACTION_TYPES.MICROSOFT_SIGN_IN_START, signInMicrosoft)
}

// export function* onCheckUserSession() {
//     yield* takeLatest(USER_ACTION_TYPES.CHECK_USER_SESSION, isUserAuthenticated)
// }

/*"Listen" to the sagas*/ 
export function* userSagas() {
    yield* all([
        // call(onCheckUserSession),
        call(onEmailSignInStart),
        call(onSignOutStart),
        call(onAddUserStart),
        call(onFetchUsersStart),
        call(onDeleteUserStart),
        call(onUpdateUserStart),
        call(onRefreshTokenStart),
        call(onMicrosoftSignInStart),
    ]);
}