import { takeLatest, put, all, call } from "typed-redux-saga/macro";

import { SETTING_ACTION_TYPES } from "./setting.types"; 
import { getDeviceSettingsList,patchDeviceSettings } from "../../utils/eqonapi/eqonapi.utils";
import { FetchSettingStart, UpdateSettingStart, fetchSettingFailed, fetchSettingSuccess, updateSettingFailed, updateSettingSuccess} from "./setting.action";


const maxTries = 3;   //number of tries for a failed api call. 1 means no retries 

// We get an action as a parameter. The action has a payload of UserData
export function* fetchDeviceSettings({payload}:FetchSettingStart){
    let retries = 0;
    while(retries<maxTries){
        try{        
            const response = yield* call(getDeviceSettingsList, payload);   //response is the json response from fetch
            if(response){
                yield* put(fetchSettingSuccess(response));     
                break;                  
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(fetchSettingFailed(error as Error));
            }
        }
    }
}

export function* updateDeviceSettings({payload}:UpdateSettingStart){
    let retries = 0;
    while(retries<maxTries){
        try{        
            const response = yield* call(patchDeviceSettings, payload.settings, payload.user);   //response is the json response from fetch
            if(response){
                yield* put(updateSettingSuccess(response));     
                break;                  
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(updateSettingFailed(error as Error));
            }
        }
    }
}

export function* onFetchDeviceSettingsStart() {
    yield* takeLatest(SETTING_ACTION_TYPES.FETCH_SETTING_START, fetchDeviceSettings)
}

export function* onUpdateDeviceSettingsStart() {
    yield* takeLatest(SETTING_ACTION_TYPES.UPDATE_SETTING_START, updateDeviceSettings)
}



/*"Listen" to the sagas*/ 
export function* deviceSettingSagas() {
    yield* all([
        call(onFetchDeviceSettingsStart),
        call(onUpdateDeviceSettingsStart)
    ]);
}