import { createSelector } from "reselect";
import { UserState } from "./user.reducer";
import { RootState } from "../store";


export const selectUserReducer = (state:RootState): UserState => state.user;


export const selectCurrentUser = createSelector(
    [selectUserReducer],    //Course: skips [] brackets
    (user) => user.currentUser
); 

export const selectUserList = createSelector(
    [selectUserReducer],    //Course: skips [] brackets
    (user) => user.userList
); 

// export const selectUserAuthToken = createSelector(
//     [selectUserReducer],    //Course: skips [] brackets
//     (user) => user.currentUser.access_token     
// ); 