import { takeLatest, put, all, call } from "typed-redux-saga/macro";

import { LOWFREQDATA_ACTION_TYPES } from "./lowfreqdata.types"; 
import { getDeviceLowFreqData } from "../../utils/eqonapi/eqonapi.utils";
import { FetchLowFreqDataStart, fetchLowFreqDataFailed, fetchLowFreqDataSuccess} from "./lowfreqdata.action";

const maxTries = 3;   //number of tries for a failed api call. 1 means no retries 

// We get an action as a parameter. The action has a payload of UserData
export function* fetchLowFreqData({payload}:FetchLowFreqDataStart){
    let retries = 0;
    while(retries<maxTries){
        try{        
            const response = yield* call(getDeviceLowFreqData, payload);   //response is the json response from fetch
            if(response){
                yield* put(fetchLowFreqDataSuccess(response));     
                break;            
            }
        }catch(error){
            retries++;  
            if (retries === maxTries) {
                yield* put(fetchLowFreqDataFailed(error as Error));
            }
        }
    }
}

export function* onFetchLowFreqDataStart() {
    yield* takeLatest(LOWFREQDATA_ACTION_TYPES.FETCH_LOWFREQDATA_START, fetchLowFreqData)
}


/*"Listen" to the sagas*/ 
export function* deviceLowFreqDataSagas() {
    yield* all([
        call(onFetchLowFreqDataStart),
    ]);
}