import { DeviceSetting } from "../../models/device.model";
import { AnyAction } from "redux";
import { fetchSettingSuccess, fetchSettingFailed, updateSettingSuccess, updateSettingFailed, addSettings } from "./setting.action";
import { toast } from "react-toastify";

export type SettingState = {
    readonly deviceSettings : DeviceSetting[] | null;
    readonly isLoading : boolean;
    readonly error: Error | null;
}

const INITIAL_STATE : SettingState = {
    deviceSettings: null,
    isLoading: false,
    error: null,
}


export const deviceSettingReducer = (state = INITIAL_STATE, action: AnyAction) => {

    if(fetchSettingSuccess.match(action)){
        return {             
            ...state,
            deviceSettings: action.payload,
            error: null
        }
    }
    
    if(fetchSettingFailed.match(action)){
        toast.error("failed to get settings")
        return { 
            ...state,
            error: action.payload             
        } 
    }
    if(updateSettingSuccess.match(action)){
        toast.success("updated settings successfully")
        
        const newDeviceSettings = state.deviceSettings?.filter((deviceSetting) => deviceSetting.owner_id!==action.payload.owner_id) //remove old settings for this device
        newDeviceSettings?.push(action.payload) //add new settings for this device

        return {             
            ...state,
            deviceSettings: newDeviceSettings,
            error: null
        }
    }
    
    if(updateSettingFailed.match(action)){
        toast.error("failed to update settings for this device")
        
        return { 
            ...state,
            error: action.payload             
        } 
    }

    if(addSettings.match(action)){
        
        const newDeviceSettings = state.deviceSettings
        newDeviceSettings?.push(action.payload.settings) //add new settings for this device

        return {             
            ...state,
            deviceSettings: newDeviceSettings,
            error: null
        }
    }
    
    return state;
};