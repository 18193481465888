export enum REPORTS_ACTION_TYPES  {
    FETCH_REPORTS_START = "reports/FETCH_REPORTS_START",
    FETCH_REPORTS_SUCCESS = "reports/FETCH_REPORTS_SUCCESS",
    FETCH_REPORTS_FAILED = "reports/FETCH_REPORTS_FAILED",
    FETCH_DAILY_REPORTS_START = "reports/FETCH_DAILY_REPORTS_START",
    FETCH_DAILY_REPORTS_SUCCESS = "reports/FETCH_DAILY_REPORTS_SUCCESS",
    FETCH_DAILY_REPORTS_FAILED = "reports/FETCH_DAILY_REPORTS_FAILED",
    FETCH_WEEKLY_REPORTS_START = "reports/FETCH_WEEKLY_REPORTS_START",
    FETCH_WEEKLY_REPORTS_SUCCESS = "reports/FETCH_WEEKLY_REPORTS_SUCCESS",
    FETCH_WEEKLY_REPORTS_FAILED = "reports/FETCH_WEEKLY_REPORTS_FAILED",
}