import { createAction } from "../../utils/reducer/reducer.utils";
import { HIGHFREQDATA_ACTION_TYPES } from "./highfreqdata.types";
import { withMatcher, ActionWithPayload } from "../../utils/reducer/reducer.utils";
import { UserData } from "../../models/user.model";
import { DeviceHighFreqData } from "../../models/device.model";


export type FetchHighFreqDataStart = ActionWithPayload<HIGHFREQDATA_ACTION_TYPES.FETCH_HIGHFREQDATA_START, UserData>
export const fetchHighFreqDataStart = withMatcher((user:UserData): FetchHighFreqDataStart => createAction(HIGHFREQDATA_ACTION_TYPES.FETCH_HIGHFREQDATA_START,user));

export type FetchHighFreqDataSuccess = ActionWithPayload<HIGHFREQDATA_ACTION_TYPES.FETCH_HIGHFREQDATA_SUCCESS, DeviceHighFreqData[]>;
export const fetchHighFreqDataSuccess = withMatcher((deviceHighFreqData:DeviceHighFreqData[]): FetchHighFreqDataSuccess => createAction(HIGHFREQDATA_ACTION_TYPES.FETCH_HIGHFREQDATA_SUCCESS,deviceHighFreqData));

export type FetchHighFreqDataFailed = ActionWithPayload<HIGHFREQDATA_ACTION_TYPES.FETCH_HIGHFREQDATA_FAILED, Error>;
export const fetchHighFreqDataFailed = withMatcher((error:Error):FetchHighFreqDataFailed => createAction(HIGHFREQDATA_ACTION_TYPES.FETCH_HIGHFREQDATA_FAILED,error));

export type AddHighFreqData = ActionWithPayload<HIGHFREQDATA_ACTION_TYPES.ADD_HIGHFREQDATA, {device_id:string, highFreqData:DeviceHighFreqData}>;
export const addHighFreqData = withMatcher((device_id:string, highFreqData:DeviceHighFreqData): AddHighFreqData => createAction(HIGHFREQDATA_ACTION_TYPES.ADD_HIGHFREQDATA,{device_id,highFreqData}));
