import { createAction } from "../../utils/reducer/reducer.utils";
import { withMatcher, ActionWithPayload } from "../../utils/reducer/reducer.utils";
import { UserData } from "../../models/user.model";
import { REPORTS_ACTION_TYPES } from "./reports.types";
import { DailyReport, Report, WeeklyReport } from "../../models/report.model";


export type FetchReportsStart = ActionWithPayload<REPORTS_ACTION_TYPES.FETCH_REPORTS_START, UserData>
export const fetchReportsStart = withMatcher((user:UserData): FetchReportsStart => createAction(REPORTS_ACTION_TYPES.FETCH_REPORTS_START,user));

export type FetchReportsSuccess = ActionWithPayload<REPORTS_ACTION_TYPES.FETCH_REPORTS_SUCCESS, Report[]>;
export const fetchReportsSuccess = withMatcher((reports:Report[]): FetchReportsSuccess => createAction(REPORTS_ACTION_TYPES.FETCH_REPORTS_SUCCESS,reports));

export type FetchReportsFailed = ActionWithPayload<REPORTS_ACTION_TYPES.FETCH_REPORTS_FAILED, Error>;
export const fetchReportsFailed = withMatcher((error:Error):FetchReportsFailed => createAction(REPORTS_ACTION_TYPES.FETCH_REPORTS_FAILED,error));

export type FetchDailyReportsStart = ActionWithPayload<REPORTS_ACTION_TYPES.FETCH_DAILY_REPORTS_START, UserData>
export const fetchDailyReportsStart = withMatcher((user:UserData): FetchDailyReportsStart => createAction(REPORTS_ACTION_TYPES.FETCH_DAILY_REPORTS_START,user));

export type FetchDailyReportsSuccess = ActionWithPayload<REPORTS_ACTION_TYPES.FETCH_DAILY_REPORTS_SUCCESS, DailyReport>;
export const fetchDailyReportsSuccess = withMatcher((report:DailyReport): FetchDailyReportsSuccess => createAction(REPORTS_ACTION_TYPES.FETCH_DAILY_REPORTS_SUCCESS,report));

export type FetchDailyReportsFailed = ActionWithPayload<REPORTS_ACTION_TYPES.FETCH_DAILY_REPORTS_FAILED, Error>;
export const fetchDailyReportsFailed = withMatcher((error:Error):FetchDailyReportsFailed => createAction(REPORTS_ACTION_TYPES.FETCH_DAILY_REPORTS_FAILED,error));

export type FetchWeeklyReportsStart = ActionWithPayload<REPORTS_ACTION_TYPES.FETCH_WEEKLY_REPORTS_START, UserData>
export const fetchWeeklyReportsStart = withMatcher((user:UserData): FetchWeeklyReportsStart => createAction(REPORTS_ACTION_TYPES.FETCH_WEEKLY_REPORTS_START,user));

export type FetchWeeklyReportsSuccess = ActionWithPayload<REPORTS_ACTION_TYPES.FETCH_WEEKLY_REPORTS_SUCCESS, WeeklyReport>;
export const fetchWeeklyReportsSuccess = withMatcher((report:WeeklyReport): FetchWeeklyReportsSuccess => createAction(REPORTS_ACTION_TYPES.FETCH_WEEKLY_REPORTS_SUCCESS,report));

export type FetchWeeklyReportsFailed = ActionWithPayload<REPORTS_ACTION_TYPES.FETCH_WEEKLY_REPORTS_FAILED, Error>;
export const fetchWeeklyReportsFailed = withMatcher((error:Error):FetchWeeklyReportsFailed => createAction(REPORTS_ACTION_TYPES.FETCH_WEEKLY_REPORTS_FAILED,error));