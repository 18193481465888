import { takeLatest, put, all, call } from "typed-redux-saga/macro";
import { FetchDailyReportsStart, FetchReportsStart, FetchWeeklyReportsStart, fetchDailyReportsFailed, fetchDailyReportsSuccess, fetchReportsFailed, fetchReportsSuccess, fetchWeeklyReportsFailed, fetchWeeklyReportsSuccess } from "./reports.action";
import { getDailyReport, getReports, getWeeklyReport } from "../../utils/eqonapi/eqonapi.utils";
import { REPORTS_ACTION_TYPES } from "./reports.types";


const maxTries = 3;   //number of tries for a failed api call. 1 means no retries 

// We get an action as a parameter. The action has a payload of UserData
export function* fetchReports({payload}:FetchReportsStart){
    let retries = 0;
    while(retries<maxTries){
        try{        
            const response = yield* call(getReports, payload);   //response is the json response from fetch
            if(response){
                yield* put(fetchReportsSuccess(response));     
                break;      
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(fetchReportsFailed(error as Error));
            }
        }
    }
}

export function* fetchDailyReports({payload}:FetchDailyReportsStart){
    let retries = 0;
    while(retries<maxTries){
        try{        
            const response = yield* call(getDailyReport, payload);
            if(response){
                yield* put(fetchDailyReportsSuccess(response));           
                break;
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(fetchDailyReportsFailed(error as Error));
            }
        }
    }
}

export function* fetchWeeklyReports({payload}:FetchWeeklyReportsStart){
    let retries = 0;
    while(retries<maxTries){
        try{        
            const response = yield* call(getWeeklyReport, payload);
            if(response){
                yield* put(fetchWeeklyReportsSuccess(response));        
                break;   
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(fetchWeeklyReportsFailed(error as Error));
            }
        }
    }
}

export function* onFetchReportsStart() {
    yield* takeLatest(REPORTS_ACTION_TYPES.FETCH_REPORTS_START, fetchReports)
}

export function* onFetchDailyReportStart() {
    yield* takeLatest(REPORTS_ACTION_TYPES.FETCH_DAILY_REPORTS_START, fetchDailyReports)
}

export function* onFetchWeeklyReportStart() {
    yield* takeLatest(REPORTS_ACTION_TYPES.FETCH_WEEKLY_REPORTS_START, fetchWeeklyReports)
}

/*"Listen" to the sagas*/ 
export function* reportsSagas() {
    yield* all([
        call(onFetchReportsStart),
        call(onFetchDailyReportStart),
        call(onFetchWeeklyReportStart),
    ]);
}