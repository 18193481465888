export type User = {
    username: string;
    full_name: string;
    email : string;
    customer: string;
    privileges: number;
    password_changed_at: Date;
    created_at: Date;
  }
  
  export type UserData = {
    access_token: string;
    access_token_expires_at : Date;
    refresh_token: string;
    refresh_token_expires_at: Date;
    user : User;
    error: string
  }

  export type AddUserModel = {
    username: string;
    password: string;
    full_name: string;
    email: string;
    privileges: number;
    customer: string;
  }


  export enum Privileges {
    Reserved_0 = 0,
    Reserved_1 = 1,
    Reserved_2 = 2,
    Reserved_3 = 3,
    Reserved_4 = 4,
    User = 5,
    Reserved_6 = 6,
    SuperUser = 7,
    CustomerAdmin = 8,
    EqonAdmin = 19
  }


  export enum PrivilegesText {
    Reserved_0 = "Reserved_0",
    Reserved_1 = "Reserved_1",
    Reserved_2 = "Reserved_2",
    Reserved_3 = "Reserved_3",
    Reserved_4 = "Reserved_4",
    User = "user",
    Reserved_6 = "Reserved_6",
    SuperUser = "superuser",
    CustomerAdmin = "customer admin",
    EqonAdmin = "eqon admin"
  }
