import { createAction } from "../../utils/reducer/reducer.utils";
import { DEVICE_ACTION_TYPES } from "./device.types";
import { withMatcher, ActionWithPayload } from "../../utils/reducer/reducer.utils";
import { UserData } from "../../models/user.model";
import { Device } from "../../models/device.model";
import { HttpStatusCode } from "axios";


export type FetchDeviceStart = ActionWithPayload<DEVICE_ACTION_TYPES.FETCH_DEVICE_START, UserData>
export const fetchDeviceStart = withMatcher((user:UserData): FetchDeviceStart => createAction(DEVICE_ACTION_TYPES.FETCH_DEVICE_START,user));

export type FetchDeviceSuccess = ActionWithPayload<DEVICE_ACTION_TYPES.FETCH_DEVICE_SUCCESS, Device[]>;
export const fetchDeviceSuccess = withMatcher((devices:Device[]): FetchDeviceSuccess => createAction(DEVICE_ACTION_TYPES.FETCH_DEVICE_SUCCESS,devices));

export type FetchDeviceFailed = ActionWithPayload<DEVICE_ACTION_TYPES.FETCH_DEVICE_FAILED, Error>;
export const fetchDeviceFailed = withMatcher((error:Error):FetchDeviceFailed => createAction(DEVICE_ACTION_TYPES.FETCH_DEVICE_FAILED,error));

export type DeleteDeviceStart = ActionWithPayload<DEVICE_ACTION_TYPES.DELETE_DEVICE_START, {user:UserData, device_id:string}>
export const deleteDeviceStart = withMatcher((user:UserData, device_id:string): DeleteDeviceStart => createAction(DEVICE_ACTION_TYPES.DELETE_DEVICE_START,{user, device_id}));

export type DeleteDeviceSuccess = ActionWithPayload<DEVICE_ACTION_TYPES.DELETE_DEVICE_SUCCESS, HttpStatusCode>;
export const deleteDeviceSuccess = withMatcher((statusCode:HttpStatusCode): DeleteDeviceSuccess => createAction(DEVICE_ACTION_TYPES.DELETE_DEVICE_SUCCESS,statusCode));

export type DeleteDeviceFailed = ActionWithPayload<DEVICE_ACTION_TYPES.DELETE_DEVICE_FAILED, Error>;
export const deleteDeviceFailed = withMatcher((error:Error):DeleteDeviceFailed => createAction(DEVICE_ACTION_TYPES.DELETE_DEVICE_FAILED,error));

export type AllocateDeviceStart = ActionWithPayload<DEVICE_ACTION_TYPES.ALLOCATE_DEVICE_START, {user:UserData, device_id:number, password: string, tagname: string, customer: string}>
export const allocateDeviceStart = withMatcher((user:UserData, device_id:number, password: string, tagname: string, customer: string): AllocateDeviceStart => createAction(DEVICE_ACTION_TYPES.ALLOCATE_DEVICE_START,{user, device_id, password, tagname, customer}));

export type AllocateDeviceSuccess = ActionWithPayload<DEVICE_ACTION_TYPES.ALLOCATE_DEVICE_SUCCESS, HttpStatusCode>;
export const allocateDeviceSuccess = withMatcher((statusCode:HttpStatusCode): AllocateDeviceSuccess => createAction(DEVICE_ACTION_TYPES.ALLOCATE_DEVICE_SUCCESS,statusCode));

export type AllocateDeviceFailed = ActionWithPayload<DEVICE_ACTION_TYPES.ALLOCATE_DEVICE_FAILED, Error>;
export const allocateDeviceFailed = withMatcher((error:Error):AllocateDeviceFailed => createAction(DEVICE_ACTION_TYPES.ALLOCATE_DEVICE_FAILED,error));