import { createAction } from "../../utils/reducer/reducer.utils";
import { withMatcher, ActionWithPayload } from "../../utils/reducer/reducer.utils";
import { UserData } from "../../models/user.model";
import { LOCATION_ACTION_TYPES } from "./location.types"; 
import { location } from "../../models/locations.model";
import { HttpStatusCode } from "axios";



export type FetchLocationStart = ActionWithPayload<LOCATION_ACTION_TYPES.FETCH_LOCATION_START, UserData>
export const fetchLocationsStart = withMatcher((user:UserData): FetchLocationStart => createAction(LOCATION_ACTION_TYPES.FETCH_LOCATION_START,user));

export type FetchLocationSuccess = ActionWithPayload<LOCATION_ACTION_TYPES.FETCH_LOCATION_SUCCESS, location[]>;
export const fetchLocationSuccess = withMatcher((locations:location[]): FetchLocationSuccess => createAction(LOCATION_ACTION_TYPES.FETCH_LOCATION_SUCCESS,locations));

export type FetchLocationFailed = ActionWithPayload<LOCATION_ACTION_TYPES.FETCH_LOCATION_FAILED, Error>;
export const fetchLocationFailed = withMatcher((error:Error):FetchLocationFailed => createAction(LOCATION_ACTION_TYPES.FETCH_LOCATION_FAILED,error));


export type CreateLocationStart = ActionWithPayload<LOCATION_ACTION_TYPES.CREATE_LOCATION_START, {user:UserData, location:location}>
export const createLocationStart = withMatcher((user:UserData, location:location): CreateLocationStart => createAction(LOCATION_ACTION_TYPES.CREATE_LOCATION_START,{user, location}));

export type CreateLocationSuccess = ActionWithPayload<LOCATION_ACTION_TYPES.CREATE_LOCATION_SUCCESS, location>;
export const createLocationSuccess = withMatcher((location:location): CreateLocationSuccess => createAction(LOCATION_ACTION_TYPES.CREATE_LOCATION_SUCCESS,location));

export type CreateLocationFailed = ActionWithPayload<LOCATION_ACTION_TYPES.CREATE_LOCATION_FAILED, Error>;
export const createLocationFailed = withMatcher((error:Error):CreateLocationFailed => createAction(LOCATION_ACTION_TYPES.CREATE_LOCATION_FAILED,error));

export type UpdateLocationCoordsStart = ActionWithPayload<LOCATION_ACTION_TYPES.UPDATE_LOCATION_COORDS_START, {user:UserData, location:location}>
export const updateLocationCoordsStart = withMatcher((user:UserData, location:location): UpdateLocationCoordsStart => createAction(LOCATION_ACTION_TYPES.UPDATE_LOCATION_COORDS_START,{user, location}));

export type UpdateLocationCoordsSuccess = ActionWithPayload<LOCATION_ACTION_TYPES.UPDATE_LOCATION_COORDS_SUCCESS, HttpStatusCode>;
export const updateLocationCoordsSuccess = withMatcher((statusCode:HttpStatusCode): UpdateLocationCoordsSuccess => createAction(LOCATION_ACTION_TYPES.UPDATE_LOCATION_COORDS_SUCCESS,statusCode));

export type UpdateLocationCoordsFailed = ActionWithPayload<LOCATION_ACTION_TYPES.UPDATE_LOCATION_COORDS_FAILED, Error>;
export const updateLocationCoordsFailed = withMatcher((error:Error):UpdateLocationCoordsFailed => createAction(LOCATION_ACTION_TYPES.UPDATE_LOCATION_COORDS_FAILED,error));

export type UpdateLocationNameStart = ActionWithPayload<LOCATION_ACTION_TYPES.UPDATE_LOCATION_NAME_START, {user:UserData, location:location}>
export const updateLocationNameStart = withMatcher((user:UserData, location:location): UpdateLocationNameStart => createAction(LOCATION_ACTION_TYPES.UPDATE_LOCATION_NAME_START,{user, location}));

export type UpdateLocationNameSuccess = ActionWithPayload<LOCATION_ACTION_TYPES.UPDATE_LOCATION_NAME_SUCCESS, HttpStatusCode>;
export const updateLocationNameSuccess = withMatcher((statusCode:HttpStatusCode): UpdateLocationNameSuccess => createAction(LOCATION_ACTION_TYPES.UPDATE_LOCATION_NAME_SUCCESS,statusCode));

export type UpdateLocationNameFailed = ActionWithPayload<LOCATION_ACTION_TYPES.UPDATE_LOCATION_NAME_FAILED, Error>;
export const updateLocationNameFailed = withMatcher((error:Error):UpdateLocationNameFailed => createAction(LOCATION_ACTION_TYPES.UPDATE_LOCATION_NAME_FAILED,error));

export type DeleteLocationStart = ActionWithPayload<LOCATION_ACTION_TYPES.DELETE_LOCATION_START, {user:UserData, location_id:string}>
export const deleteLocationStart = withMatcher((user:UserData, location_id:string): DeleteLocationStart => createAction(LOCATION_ACTION_TYPES.DELETE_LOCATION_START,{user, location_id}));

export type DeleteLocationSuccess = ActionWithPayload<LOCATION_ACTION_TYPES.DELETE_LOCATION_SUCCESS, {statusCode:HttpStatusCode, location_id:string}>;
export const deleteLocationSuccess = withMatcher((statusCode:HttpStatusCode,location_id:string): DeleteLocationSuccess => createAction(LOCATION_ACTION_TYPES.DELETE_LOCATION_SUCCESS,{statusCode,location_id}));

export type DeleteLocationFailed = ActionWithPayload<LOCATION_ACTION_TYPES.DELETE_LOCATION_FAILED, Error>;
export const deleteLocationFailed = withMatcher((error:Error):DeleteLocationFailed => createAction(LOCATION_ACTION_TYPES.DELETE_LOCATION_FAILED,error));

