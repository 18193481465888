import { DeviceHighFreqData } from "../../models/device.model";
import { AnyAction } from "redux";
import { fetchHighFreqDataSuccess, fetchHighFreqDataFailed, addHighFreqData } from "./highfreqdata.action";

export type HighFreqDataState = {
    readonly deviceHighFreqData : DeviceHighFreqData[] | null;
    readonly isLoading : boolean;
    readonly error: Error | null;
}

const INITIAL_STATE : HighFreqDataState = {
    deviceHighFreqData: null,
    isLoading: false,
    error: null,
}


export const deviceHighFreqDataReducer = (state = INITIAL_STATE, action: AnyAction) => {

    if(fetchHighFreqDataSuccess.match(action)){
        return {             
            ...state,
            deviceHighFreqData: action.payload,
            error: null
        }
    }
    
    if(fetchHighFreqDataFailed.match(action)){
        return { 
            ...state,
            error: action.payload             
        } 
    }

    if(addHighFreqData.match(action)){
        const newDeviceHighFreqData = state.deviceHighFreqData
        newDeviceHighFreqData?.push(action.payload.highFreqData) //add new highFreqData for this device

        return {             
            ...state,
            deviceHighFreqData: newDeviceHighFreqData,
            error: null
        }
    }
    
    return state;
};