import { takeLatest, put, all, call } from "typed-redux-saga/macro";
import { CUSTOMER_ACTION_TYPES } from "./customer.types";
import { AddCustomerStart, DeleteCustomerStart, FetchCustomerStart, UpdateCustomerStart, addCustomerFailed, addCustomerSuccess, deleteCustomerFailed, deleteCustomerSuccess, fetchCustomerFailed, fetchCustomerSuccess, updateCustomerFailed, updateCustomerSuccess } from "./customer.action";
import { addCustomerAPI, deleteCustomerAPI, getCustomers, patchCustomer } from "../../utils/eqonapi/eqonapi.utils";
import { HttpStatusCode } from "axios";

const maxTries = 3;   //number of tries for a failed api call. 1 means no retries 


export function* fetchCustomers({payload: {user}}:FetchCustomerStart){

    let retries = 0;
    while(retries<maxTries){
        try{
            const response = yield* call(getCustomers, user);
            if(response){
                yield* put(fetchCustomerSuccess(response));
                break;
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(fetchCustomerFailed(error as Error));
            }
        }
    }
}


export function* updateCustomer({payload: {user, customer}}:UpdateCustomerStart){

    let retries = 0;
    while(retries<maxTries){
        try{
            const response = yield* call(patchCustomer, user, customer);
            if(response){
                yield* put(updateCustomerSuccess());
                break;
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(updateCustomerFailed(error as Error));
            }
        }
    }
}

export function* deleteCustomer({payload: {user, id}}:DeleteCustomerStart){

    let retries = 0;
    while(retries<maxTries){
        try{
            const response = yield* call(deleteCustomerAPI, user, id);
            if (response===HttpStatusCode.Ok){                
                yield* put(deleteCustomerSuccess());
                break;
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(deleteCustomerFailed(error as Error));
            }
        }
    }
}

export function* addCustomer({payload: {user, customer_name}}:AddCustomerStart){

    let retries = 0;
    while(retries<maxTries){
        try{
            const response = yield* call(addCustomerAPI, user, customer_name);
            if(response){
                yield* put(addCustomerSuccess());
                break;
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(addCustomerFailed(error as Error));
            }
        }
    }
}


export function* onFetchCustomerStart() {
    yield* takeLatest(CUSTOMER_ACTION_TYPES.FETCH_CUSTOMER_START, fetchCustomers)
}

export function* onUpdateCustomerStart() {
    yield* takeLatest(CUSTOMER_ACTION_TYPES.UPDATE_CUSTOMER_START, updateCustomer)
}

export function* onDeleteCustomerStart() {
    yield* takeLatest(CUSTOMER_ACTION_TYPES.DELETE_CUSTOMER_START, deleteCustomer)
}

export function* onAddCustomerStart() {
    yield* takeLatest(CUSTOMER_ACTION_TYPES.ADD_CUSTOMER_START, addCustomer)
}


/*"Listen" to the sagas*/ 
export function* customerSagas() {
    yield* all([
        call(onFetchCustomerStart),
        call(onUpdateCustomerStart),
        call(onDeleteCustomerStart),
        call(onAddCustomerStart),
    ]);
}