import {all, call} from "typed-redux-saga/macro"
import { userSagas } from "./user/user.saga";
import { deviceSagas } from "./device/device.saga";
import { deviceIdSagas } from "./idsetting/idsetting.saga";
import { deviceSettingSagas } from "./setting/setting.saga";
import { deviceHighFreqDataSagas } from "./highfreqdata/highfreqdata.saga";
import { deviceLowFreqDataSagas } from "./lowfreqdata/lowfreqdata.saga";
import { customerSagas } from "./customer/customer.saga";
import { alertSagas } from "./alert/alert.saga";
import { reportsSagas } from "./reports/reports.saga";
import { locationSagas } from "./location/location.saga";

export function* rootSaga() {
    yield* all(
        [call(userSagas), call(deviceSagas), call(deviceIdSagas), call(deviceSettingSagas), call(deviceHighFreqDataSagas), call(deviceLowFreqDataSagas), call(customerSagas), call(alertSagas), call(reportsSagas), call(locationSagas)]);
}
