export enum DEVICE_ACTION_TYPES  {
    FETCH_DEVICE_START = "device/FETCH_DEVICE_START",
    FETCH_DEVICE_SUCCESS = "device/FETCH_DEVICE_SUCCESS",
    FETCH_DEVICE_FAILED = "device/FETCH_DEVICE_FAILED",
    DELETE_DEVICE_START = "device/DELETE_DEVICE_START",
    DELETE_DEVICE_SUCCESS = "device/DELETE_DEVICE_SUCCESS",
    DELETE_DEVICE_FAILED = "device/DELETE_DEVICE_FAILED",
    ALLOCATE_DEVICE_START = "device/ALLOCATE_DEVICE_START",
    ALLOCATE_DEVICE_SUCCESS = "device/ALLOCATE_DEVICE_SUCCESS",
    ALLOCATE_DEVICE_FAILED = "device/ALLOCATE_DEVICE_FAILED",
}