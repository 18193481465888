import { Device } from "../../models/device.model";
import { AnyAction } from "redux";
import { allocateDeviceFailed, allocateDeviceSuccess, deleteDeviceFailed, deleteDeviceSuccess, fetchDeviceFailed, fetchDeviceSuccess } from "./device.action";
import { HttpStatusCode } from "axios";
import { toast } from "react-toastify";

export type DeviceState = {
    readonly devices : Device[] | null;
    readonly isLoading : boolean;
    readonly error: Error | null;
}

const INITIAL_STATE : DeviceState = {
    devices: null,
    isLoading: false,
    error: null,
}


export const deviceReducer = (state = INITIAL_STATE, action: AnyAction) => {

    if(fetchDeviceSuccess.match(action)){
        return {             
            ...state,
            devices: action.payload,
            error: null
        }
    }
    
    if(fetchDeviceFailed.match(action)){
        return { 
            ...state,
            error: action.payload             
        } 
    }
    if(deleteDeviceSuccess.match(action)){
        if(action.payload===HttpStatusCode.Ok){
            toast.success("device deleted successfully")
        }
        return {             
            ...state,
            error: null
        }
    }
    
    if(deleteDeviceFailed.match(action)){
        toast.error("failed to delete device")
        return {
            ...state,
            error: action.payload             
        } 
    }

    if(allocateDeviceSuccess.match(action)){
        //statuscode is created or conflict. Conflict means device is already allocated.
        if(action.payload===HttpStatusCode.Created ){
            toast.success("device allocated successfully")
        }
        if(action.payload===HttpStatusCode.Conflict){
            toast.info("device is already registered")
        }
        return {             
            ...state,
            error: null
        }
    }
    
    if(allocateDeviceFailed.match(action)){
        action.payload.message.includes("409") ? toast.info("device is already registered") :
        toast.error("failed to allocate device")
        return {
            ...state,
            error: action.payload             
        } 
    }
    return state;
};