export enum ALERT_ACTION_TYPES  {
    FETCH_ALERTS_START = "alert/FETCH_ALERTS_START",
    FETCH_ALERTS_SUCCESS = "alert/FETCH_ALERTS_SUCCESS",
    FETCH_ALERTS_FAILED = "alert/FETCH_ALERTS_FAILED",
    ACKNOWLEDGE_ALERT_START =  "alert/ACKNOWLEDGE_ALERT_START",
    ACKNOWLEDGE_ALERT_SUCCESS =  "alert/ACKNOWLEDGE_ALERT_SUCCESS",
    ACKNOWLEDGE_ALERT_FAILED =  "alert/ACKNOWLEDGE_ALERT_FAILED",
    DELETE_ALERT_START =  "alert/DELETE_ALERT_START",
    DELETE_ALERT_SUCCESS =  "alert/DELETE_ALERT_SUCCESS",
    DELETE_ALERT_FAILED =  "alert/DELETE_ALERT_FAILED",
}