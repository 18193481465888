import { HttpStatusCode } from "axios";
import { Alert } from "../models/alert.model";

import { toast } from "react-toastify";

export const getData = async <T>(url:string): Promise<T> => {
    const response = await fetch(url);
    return await response.json();
}

export const signIn = async<T> (url:string, username: string, password: string): Promise<T> => {
   
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type":"application/json"},
        body: JSON.stringify({
          username:username,
          password:password
        })
      };
    const response = await fetch(url +"users/login",requestOptions);    
    return await  response.json()
  }


  interface requestOptions {
    method: string;
    headers: Headers;
    body: string;
  }

  interface getRequestOptions {
    method: string;
    headers: Headers;
  }


  export const postAllocateDevice = async<T>(url: string, opts:requestOptions): Promise<T> => {

    const response = await fetch(url,opts);
    
    if (response.status===HttpStatusCode.Created){
      toast.success("Device added successfully")
    }else{
      toast.error("Failed to add device")
    }

    return await response.json()  
  }

  export const getAlertListRequest = async (url: string, opts:getRequestOptions): Promise<Alert[]> => {

    const response = await fetch(url,opts);
    return await response.json()
  }


  