import { createAction } from "../../utils/reducer/reducer.utils";
import { USER_ACTION_TYPES } from "./user.types";
import { withMatcher, Action, ActionWithPayload } from "../../utils/reducer/reducer.utils";
import { AddUserModel, UserData } from "../../models/user.model";
import { User } from "../../models/user.model";


export type CheckUserSession = Action<USER_ACTION_TYPES.CHECK_USER_SESSION>;
export const checkUserSession = withMatcher(():CheckUserSession => createAction(USER_ACTION_TYPES.CHECK_USER_SESSION));

export type SetCurrentUser =  ActionWithPayload<USER_ACTION_TYPES.SET_CURRENT_USER, UserData>;
export const setCurrentUser = withMatcher((user:UserData):SetCurrentUser => createAction(USER_ACTION_TYPES.SET_CURRENT_USER, user));

export type EmailSignInStart = ActionWithPayload<USER_ACTION_TYPES.EMAIL_SIGN_IN_START,{username: string, password:string}>
export const emailSignInStart = withMatcher((username:string, password:string): EmailSignInStart => createAction(USER_ACTION_TYPES.EMAIL_SIGN_IN_START, {username, password}));

export type SignInSuccess = ActionWithPayload<USER_ACTION_TYPES.SIGN_IN_SUCCESS, UserData>;
export const signInSuccess = withMatcher((user:UserData): SignInSuccess => createAction(USER_ACTION_TYPES.SIGN_IN_SUCCESS,user));

export type SignInFailed = ActionWithPayload<USER_ACTION_TYPES.SIGN_IN_FAILED, Error>;
export const signInFailed = withMatcher((error:Error):SignInFailed => createAction(USER_ACTION_TYPES.SIGN_IN_FAILED,error));

export type SignOutStart = Action<USER_ACTION_TYPES.SIGN_OUT_START>;
export const signOutStart = withMatcher(() => createAction(USER_ACTION_TYPES.SIGN_OUT_START));

export type SignOutSuccess = ActionWithPayload<USER_ACTION_TYPES.SIGN_OUT_SUCCESS, {user:UserData|null}>;
export const signOutSuccess = withMatcher((user:UserData|null) => createAction(USER_ACTION_TYPES.SIGN_OUT_SUCCESS, user));

export type SignOutFailed = ActionWithPayload<USER_ACTION_TYPES.SIGN_OUT_FAILED, Error>;
export const signOutFailed = withMatcher((error:Error) => createAction(USER_ACTION_TYPES.SIGN_OUT_FAILED, error));

export type AddUserStart = ActionWithPayload<USER_ACTION_TYPES.ADD_USER_START, {user:UserData, userToAdd:AddUserModel}>;
export const addUserStart = withMatcher((user:UserData, userToAdd:AddUserModel) => createAction(USER_ACTION_TYPES.ADD_USER_START,{user,userToAdd }));

export type AddUserSuccess = Action<USER_ACTION_TYPES.ADD_USER_SUCCESS>;
export const addUserSuccess = withMatcher(() => createAction(USER_ACTION_TYPES.ADD_USER_SUCCESS));

export type AddUserFailed = ActionWithPayload<USER_ACTION_TYPES.ADD_USER_FAILED, Error>;
export const addUserFailed = withMatcher((error:Error) => createAction(USER_ACTION_TYPES.ADD_USER_FAILED, error));

export type DeleteUserStart = ActionWithPayload<USER_ACTION_TYPES.DELETE_USER_START, {user:UserData, username:string}>;
export const deleteUserStart = withMatcher((user:UserData, username:string) => createAction(USER_ACTION_TYPES.DELETE_USER_START,{user,username }));

export type DeleteUserSuccess = Action<USER_ACTION_TYPES.DELETE_USER_SUCCESS>;
export const deleteUserSuccess = withMatcher(() => createAction(USER_ACTION_TYPES.DELETE_USER_SUCCESS));

export type DeleteUserFailed = ActionWithPayload<USER_ACTION_TYPES.DELETE_USER_FAILED, Error>;
export const deleteUserFailed = withMatcher((error:Error) => createAction(USER_ACTION_TYPES.DELETE_USER_FAILED, error));

export type FetchUserStart = ActionWithPayload<USER_ACTION_TYPES.FETCH_USERS_START, UserData>;
export const fetchUserStart = withMatcher((user:UserData) => createAction(USER_ACTION_TYPES.FETCH_USERS_START,user));

export type FetchUserSuccess = ActionWithPayload<USER_ACTION_TYPES.FETCH_USERS_SUCCESS, User[]>;
export const fetchUserSuccess = withMatcher((users:User[]) => createAction(USER_ACTION_TYPES.FETCH_USERS_SUCCESS, users));

export type FetchUserFailed = ActionWithPayload<USER_ACTION_TYPES.FETCH_USERS_FAILED, Error>;
export const fetchUserFailed = withMatcher((error:Error) => createAction(USER_ACTION_TYPES.FETCH_USERS_FAILED, error));

export type UpdateUserStart = ActionWithPayload<USER_ACTION_TYPES.UPDATE_USER_START, {user:UserData,username:string, privileges:number, full_name: string, email:string}>;
export const updateUserStart = withMatcher((user:UserData,username:string, privileges:number, full_name: string, email:string) => createAction(USER_ACTION_TYPES.UPDATE_USER_START,{user, username, full_name, privileges, email}));

export type UpdateUserSuccess = ActionWithPayload<USER_ACTION_TYPES.UPDATE_USER_SUCCESS, User>;
export const updateUserSuccess = withMatcher((user:User) => createAction(USER_ACTION_TYPES.UPDATE_USER_SUCCESS, user));

export type UpdateUserFailed = ActionWithPayload<USER_ACTION_TYPES.UPDATE_USER_FAILED, Error>;
export const updateUserFailed = withMatcher((error:Error) => createAction(USER_ACTION_TYPES.UPDATE_USER_FAILED, error));

export type RefreshTokenStart = ActionWithPayload<USER_ACTION_TYPES.REFRESH_TOKEN_START, {user:UserData}>;
export const refreshTokenStart = withMatcher((user:UserData) => createAction(USER_ACTION_TYPES.REFRESH_TOKEN_START,{user}));

export type RefreshTokenSuccess = ActionWithPayload<USER_ACTION_TYPES.REFRESH_TOKEN_SUCCESS, {access_token:string, access_token_expires_at:Date} >;
export const refreshTokenSuccess = withMatcher((access_token:string, access_token_expires_at:Date) => createAction(USER_ACTION_TYPES.REFRESH_TOKEN_SUCCESS, {access_token,access_token_expires_at}));

export type RefreshTokenFailed = ActionWithPayload<USER_ACTION_TYPES.REFRESH_TOKEN_FAILED, Error>;
export const refreshTokenFailed = withMatcher((error:Error) => createAction(USER_ACTION_TYPES.REFRESH_TOKEN_FAILED, error));

export type MicrosoftSignInStart = ActionWithPayload<USER_ACTION_TYPES.MICROSOFT_SIGN_IN_START,{idtoken: string}>
export const microsoftSignInStart = withMatcher((idtoken:string): MicrosoftSignInStart => createAction(USER_ACTION_TYPES.MICROSOFT_SIGN_IN_START, {idtoken}));
