import { takeLatest, put, all, call } from "typed-redux-saga/macro";

import { IDSETTING_ACTION_TYPES } from "./idsetting.types"; 
import { getDeviceIdList, patchDeviceIdSettings } from "../../utils/eqonapi/eqonapi.utils";
import { FetchIdSettingStart, UpdateIdSettingStart, fetchIdSettingFailed, fetchIdSettingSuccess, updateIdSettingFailed, updateIdSettingSuccess} from "./idsetting.action";

const maxTries = 3;   //number of tries for a failed api call. 1 means no retries 


// We get an action as a parameter. The action has a payload of UserData
export function* fetchDeviceIdSettings({payload}:FetchIdSettingStart){
    let retries = 0;
    while(retries<maxTries){
        try{        
            const response = yield* call(getDeviceIdList, payload);   //response is the json response from fetch
            if(response){
                yield* put(fetchIdSettingSuccess(response));        
                break;   
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(fetchIdSettingFailed(error as Error));
            }
        }
    }
}

export function* updateDeviceIdSettings({payload}:UpdateIdSettingStart){
    let retries = 0;
    while(retries<maxTries){
        try{        
            const response = yield* call(patchDeviceIdSettings, payload.settings, payload.user);   //response is the json response from fetch
            if(response){
                yield* put(updateIdSettingSuccess(response));        
                break;   
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(updateIdSettingFailed(error as Error));
            }
        }
    }
}


export function* onFetchDeviceIdSettingsStart() {
    yield* takeLatest(IDSETTING_ACTION_TYPES.FETCH_IDSETTING_START, fetchDeviceIdSettings)
}

export function* onUpdateDeviceIdSettingsStart() {
    yield* takeLatest(IDSETTING_ACTION_TYPES.UPDATE_IDSETTING_START, updateDeviceIdSettings)
}

/*"Listen" to the sagas*/ 
export function* deviceIdSagas() {
    yield* all([
        call(onFetchDeviceIdSettingsStart),
        call(onUpdateDeviceIdSettingsStart)
    ]);
}