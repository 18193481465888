export enum LOCATION_ACTION_TYPES  {
    CREATE_LOCATION_START =  "LOCATION/CREATE_LOCATION_START",
    CREATE_LOCATION_SUCCESS =  "LOCATION/CREATE_LOCATION_SUCCESS",
    CREATE_LOCATION_FAILED =  "LOCATION/CREATE_LOCATION_FAILED",
    FETCH_LOCATION_START = "LOCATION/FETCH_LOCATION_START",
    FETCH_LOCATION_SUCCESS = "LOCATION/FETCH_LOCATION_SUCCESS",
    FETCH_LOCATION_FAILED = "LOCATION/FETCH_LOCATION_FAILED",
    UPDATE_LOCATION_COORDS_START =  "LOCATION/UPDATE_LOCATION_COORDS_START",
    UPDATE_LOCATION_COORDS_SUCCESS =  "LOCATION/UPDATE_LOCATION_COORDS_SUCCESS",
    UPDATE_LOCATION_COORDS_FAILED =  "LOCATION/UPDATE_LOCATION_COORDS_FAILED",
    UPDATE_LOCATION_NAME_START =  "LOCATION/UPDATE_LOCATION_NAME_START",
    UPDATE_LOCATION_NAME_SUCCESS =  "LOCATION/UPDATE_LOCATION_NAME_SUCCESS",
    UPDATE_LOCATION_NAME_FAILED =  "LOCATION/UPDATE_LOCATION_NAME_FAILED",
    DELETE_LOCATION_START =  "LOCATION/DELETE_LOCATION_START",
    DELETE_LOCATION_SUCCESS =  "LOCATION/DELETE_LOCATION_SUCCESS",
    DELETE_LOCATION_FAILED =  "LOCATION/DELETE_LOCATION_FAILED",
}